import { useCoreHandler } from '@/components/Canvas/handlers'
import { useCanvasContext } from '@/components/Canvas/hooks'
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
function ControlLayer() {
  const { activeObject, canvas } = useCanvasContext()
  return (
    <div>
      {/* {activeObject != null && (
        <Button
          onClick={() => {
            activeObject.bringToFront()
            canvas.renderAll()
          }}
        >
          bringToFront
        </Button>
      )}
      {activeObject != null && (
        <Button
          onClick={() => {
            activeObject.bringForward()
            canvas.renderAll()
          }}
        >
          bringForward
        </Button>
      )}
      {activeObject != null && (
        <Button
          onClick={() => {
            canvas.sendToBack(activeObject)
            canvas.renderAll()
          }}
        >
          sendToBack
        </Button>
      )} */}
      {/* {activeObject != null && (
        <Button
          onClick={() => {
            //@ts-ignore
            const workarea = canvas.getObjects().find(obj => obj.id === 'workarea')
            console.log('workarea:', workarea)
            activeObject.clipPath = workarea
            canvas.renderAll()
          }}
        >
          clip frame
        </Button>
      )} */}
    </div>
  )
}

export default ControlLayer
