export const FontList = [
  'Open Sans',
  'Lexend',
  'Comic Neue',
  'Patrick Hand',
  'FatBlack',
  'FzThuPhapTieuTu',
  'UVFCandlescriptPro',
  'FHCordelia-Regular',
  'THManchesterSignature',
  'SVNHCAlterner',
  'SVNHCYeomanGothic',
  'SVNHCTransformacio',
  'SVNHCStrenuous',
  'SVNHCOldNewspaperTypes',
  'SVNHCNewyorkClean',
  'SVNHCOrganGrinder',
  'UVF Barmbrack',
]

export const FontSVN = [
  'SVN-A Love Of Thunder',
  'SVN-Aaron Script',
  'SVN-Abril Fatface',
  'SVN-Acherus Italic',
  'SVN-Acherus Regular',
  'SVN-Adam Gorry',
  'SVN-Agency FB Bold',
  'SVN-Agency FB',
  'SVN-Aguda Black',
  'SVN-Aguda Bold',
  'SVN-Aguda Light',
  'SVN-Aguda Regular',
  'SVN-Alek Bold',
  'SVN-Alek Ornaments',
  'SVN-Alek Regular',
  'SVN-Aleo Bold Italic',
  'SVN-Aleo Bold',
  'SVN-Aleo Italic',
  'SVN-Aleo Light Italic',
  'SVN-Aleo Light',
  'SVN-Aleo Regular',
  'SVN-Aller Regular',
  'SVN-Amethyst Dingbats',
  'SVN-Amethyst TrueType',
  'SVN-Amethyst',
  'SVN-Amperzand',
  'SVN-Amsi Cond Italic',
  'SVN-Amsi Cond Regular',
  'SVN-Amsi Narw Bold',
  'SVN-Amsi Narw Light',
  'SVN-Anastasia',
  'SVN-Androgyne',
  'SVN-Angellife',
  'SVN-Angely Extras',
  'SVN-Angely Summer',
  'SVN-Angely',
  'SVN-Anthem',
  'SVN-Apple',
  'SVN-Appleberry',
  'SVN-Aptima bold italic',
  'SVN-Aptima bold',
  'SVN-Aptima italic',
  'SVN-Aptima',
  'SVN-Arch Regular',
  'SVN-Archaic 1897',
  'SVN-Arial 2 bold italic',
  'SVN-Arial 2 bold',
  'SVN-Arial 2 italic',
  'SVN-Arial 2',
  'SVN-Arial 3 bold italic',
  'SVN-Arial 3 bold',
  'SVN-Arial 3 itlaic',
  'SVN-Arial 3',
  'SVN-Arial Bold Italic',
  'SVN-Arial Bold',
  'SVN-Arial Italic',
  'SVN-Arial Regular',
  'SVN-Aslang Barry',
  'SVN-Astronout',
  'SVN-Athena',
  'SVN-Avaline Script Catchwords',
  'SVN-Avaline Script Ornaments',
  'SVN-Avaline Script',
  'SVN-Avengeance',
  'SVN-Avo bold italic',
  'SVN-Avo bold',
  'SVN-Avo italic',
  'SVN-Avo',
  'SVN-AZ Cupcakes',
  'SVN-Bali Script',
  'SVN-Balladeer Light',
  'SVN-Ballarea Typeface',
  'SVN-Banana Chip Muffins',
  'SVN-Bango',
  'SVN-Bariol Italic',
  'SVN-Bariol',
  'SVN-Batman Forever Alternate',
  'SVN-Bear',
  'SVN-Beast',
  'SVN-Beauty Atok Script',
  'SVN-Bellico',
  'SVN-Bellwethers',
  'SVN-Beloved',
  'SVN-Berkshire Swash',
  'SVN-Billo',
  'SVN-Bio Sans Regular',
  'SVN-Bira',
  'SVN-Birth Of A Hero',
  'SVN-Bistro Script',
  'SVN-Blade runner',
  'SVN-Blenda Script',
  'SVN-Block',
  'SVN-Blog Script',
  'SVN-Blue',
  'SVN-Book Antiqua bold italic',
  'SVN-Book Antiqua bold',
  'SVN-Book Antiqua italic',
  'SVN-Book Antiqua',
  'SVN-Bradley Hand',
  'SVN-Braley Hand',
  'SVN-Bree Regular',
  'SVN-Brother 1816 Italic',
  'SVN-Brother 1816 Regular',
  'SVN-Brunette',
  'SVN-Bruselo Script Ornaments',
  'SVN-Bruselo Script',
  'SVN-Bulgary',
  'SVN-Cafe Lounge 19',
  'SVN-Calama',
  'SVN-Calling Code Bold Italic',
  'SVN-Calling Code Bold',
  'SVN-Calling Code Italic',
  'SVN-Calling Code Regular',
  'SVN-Candy Script',
  'SVN-Caprica Script',
  'SVN-Carington',
  'SVN-Cattleya Script',
  'SVN-Channel',
  'SVN-Cherryla',
  'SVN-Ciao Bella Flowers',
  'SVN-Ciao Bella Laurels',
  'SVN-Ciao Bella Leaves',
  'SVN-Ciao Bella Stems',
  'SVN-Ciao Bella',
  'SVN-Cinnamoncake',
  'SVN-Cintra',
  'SVN-Clementine',
  'SVN-Coco FY',
  'SVN-Comic Sans MS',
  'SVN-Cookie',
  'SVN-Cookies',
  'SVN-Corki Regular',
  'SVN-Corki Rounded',
  'SVN-Corki Tuscan Rounded',
  'SVN-Corki Tuscan',
  'SVN-D Sari Regular',
  'SVN-Daisy',
  'SVN-Dancing script',
  'SVN-Darleston',
  'SVN-Days',
  'SVN-Deadhead Script',
  'SVN-Desire',
  'SVN-Dessert Menu Script',
  'SVN-DK Clochard',
  'SVN-Dumpling',
  'SVN-Effra Italic',
  'SVN-Effra',
  'SVN-Egregio Script',
  'SVN-Elle NovaC Extra Light',
  'SVN-Endless Sorrow Catchword',
  'SVN-Endless Sorrow',
  'SVN-Every Movie Every Night',
  'SVN-Fadilla',
  'SVN-FFF Tusj',
  'SVN-Fiolex Girls',
  'SVN-Fjalla One',
  'SVN-Flamingo Script',
  'SVN-Fourth Bold',
  'SVN-Fourth Extras',
  'SVN-Fourth Regular',
  'SVN-Franko',
  'SVN-Freestyle',
  'SVN-Genica Pro',
  'SVN-Georgia Bold Italic',
  'SVN-Georgia Bold',
  'SVN-Georgia Italic',
  'SVN-Georgia Regular',
  'SVN-Gidry',
  'SVN-Good Dog',
  'SVN-Gotham Rounded Regular',
  'SVN-Gretoon',
  'SVN-Grocery Rounded',
  'SVN-Hand Gothic Bold',
  'SVN-Hand Gothic Regular',
  'SVN-Haptic Script',
  'SVN-Harabaras',
  'SVN-Harman Script',
  'SVN-Heathergreen',
  'SVN-Helga',
  'SVN-Helves bold italic',
  'SVN-Helves bold',
  'SVN-Helves italic',
  'SVN-Helves',
  'SVN-Helvetica Neue Bold Italic',
  'SVN-Helvetica Neue Bold',
  'SVN-Helvetica Neue Heavy',
  'SVN-Helvetica Neue Italic',
  'SVN-Helvetica Neue Light',
  'SVN-Helvetica Neue Regular',
  'SVN-Hemi Head',
  'SVN-Heroe Pro Fleurons',
  'SVN-Heroe Pro Inline',
  'SVN-Heroe Pro Monoline',
  'SVN-Heroe Pro',
  'SVN-Hole Hearted',
  'SVN-Holidays',
  'SVN-Hollie Script Pro',
  'SVN-Hollycakes',
  'SVN-Indie',
  'SVN-Internation',
  'SVN-Justice League',
  'SVN-Kelson Sans Bold',
  'SVN-Kelson Sans Light',
  'SVN-Kelson Sans Regular',
  'SVN-KG Ten Thousand Reasons',
  'SVN-Kimberley',
  'SVN-Kitten',
  'SVN-Larch Shaded',
  'SVN-Linux Libertine bold italic',
  'SVN-Linux Libertine bold',
  'SVN-Linux Libertine Italic',
  'SVN-Linux Libertine regular',
  'SVN-Lobster',
  'SVN-Madre Script',
  'SVN-Manus',
  'SVN-Maphylla',
  'SVN-Marpesia Pro',
  'SVN-Marujo Fino',
  'SVN-Marujo Pictures',
  'SVN-Marujo',
  'SVN-Mercury Script Bold',
  'SVN-Mercury Script Light',
  'SVN-Mercury Script Ornaments Bold',
  'SVN-Mercury Script Ornaments Light',
  'SVN-Mercury Script Ornaments Regular',
  'SVN-Mercury Script Regular',
  'SVN-Merge Bold',
  'SVN-Merge Regular',
  'SVN-Mikado Regular',
  'SVN-Miller Banner Italic',
  'SVN-Miller Banner',
  'SVN-Monday Ornaments',
  'SVN-Monday',
  'SVN-Moon Star',
  'SVN-MORVA Ornaments',
  'SVN-MORVA',
  'SVN-Motion Picture',
  'SVN-Mutlu',
  'SVN-Neogrey',
  'SVN-Neusa Bold',
  'SVN-Neusa',
  'SVN-Neutraface 2',
  'SVN-New Athletic M54',
  'SVN-Newton',
  'SVN-Nexa Bold',
  'SVN-Nexa Light',
  'SVN-Nexa Rush Handmade Extended',
  'SVN-Nexa Rush Sans Black',
  'SVN-Nexa Rush Script',
  'SVN-Nexa Rush Slab Black Shadow',
  'SVN-Nexa Rust Extras Free',
  'SVN-Nexa Rust Sans Black',
  'SVN-Nexa Rust Script',
  'SVN-Nexa Rust Slab Black Shadow',
  'SVN-Nickainley Script',
  'SVN-Nickel',
  'SVN-Nothing',
  'SVN-Novelia',
  'SVN-Olivier',
  'SVN-Oscine',
  'SVN-OscineBold',
  'SVN-Paris Pro',
  'SVN-Passion',
  'SVN-PF Din Text Pro Compressed Regular',
  'SVN-PF Din Text Pro Condensed Regular',
  'SVN-PF Din Text Pro Regular',
  'SVN-Pokys',
  'SVN-Posterizer KG Inline',
  'SVN-Prima',
  'SVN-Product Sans Bold',
  'SVN-Product Sans Regular',
  'SVN-Pyes Pa Headline',
  'SVN-Ready',
  'SVN-Redressed',
  'SVN-Reklame Script',
  'SVN-Reliant',
  'SVN-Revolution',
  'SVN-Rex',
  'SVN-RidemyBike Pro Bold',
  'SVN-RidemyBike Pro Dingbat',
  'SVN-RidemyBike Pro Regular',
  'SVN-Riesling',
  'SVN-Ringdena',
  'SVN-Rio 2016',
  'SVN-Rocker',
  'SVN-Rosalinda',
  'SVN-Roselina Script',
  'SVN-Rounded',
  'SVN-Russell',
  'SVN-SAF',
  'SVN-Safina Script',
  'SVN-Saldina',
  'SVN-Sansation',
  'SVN-Santis',
  'SVN-Sari Regular',
  'SVN-Sarifa',
  'SVN-Segoe Print',
  'SVN-Segoe Script',
  'SVN-Segoe UI Bold Italic',
  'SVN-Segoe UI Bold',
  'SVN-Segoe UI Condensed bold',
  'SVN-Segoe UI Condensed',
  'SVN-Segoe UI Italic',
  'SVN-Segoe UI Light Italic',
  'SVN-Segoe UI Light',
  'SVN-Segoe UI',
  'SVN-Servetica Medium',
  'SVN-Servetica Thin',
  'SVN-Sevillana',
  'SVN-Shintia Script',
  'SVN-Showcase Ornaments',
  'SVN-Showcase Sans',
  'SVN-Showcase Script',
  'SVN-Showcase Slab',
  'SVN-Sign Painter House Script',
  'SVN-Skill',
  'SVN-Sliced',
  'SVN-Snail',
  'SVN-Snell Roundhand Script Bold',
  'SVN-Snell Roundhand Script',
  'SVN-Sofia',
  'SVN-Square',
  'SVN-Standly',
  'SVN-Steady',
  'SVN-Stella',
  'SVN-Strangelove',
  'SVN-Student',
  'SVN-Suargie',
  'SVN-Sunday',
  'SVN-SVN-A Love Of Thunder',
  'SVN-Swashington',
  'SVN-Taiga',
  'SVN-Tamarindo',
  'SVN-Teaters',
  'SVN-The Carpenter Bold',
  'SVN-The Carpenter Ornaments',
  'SVN-The Carpenter Patterns',
  'SVN-The Carpenter Pictograms',
  'SVN-The Carpenter Regular',
  'SVN-The Voice Heavy Italic',
  'SVN-The Voice Heavy Italic',
  'SVN-The Voice Light',
  'SVN-The Voice Light',
  'SVN-The Voice Regular',
  'SVN-The Voice Regular',
  'SVN-Times New Roman 2 bold italic',
  'SVN-Times New Roman 2 bold',
  'SVN-Times New Roman 2 italic',
  'SVN-Times New Roman 2',
  'SVN-Times New Roman Bold Italic',
  'SVN-Times New Roman Bold',
  'SVN-Times New Roman Italic',
  'SVN-Times New Roman',
  'SVN-Titillium bold',
  'SVN-Titillium light',
  'SVN-Titillium medium',
  'SVN-TradeMark',
  'SVN-Transformer',
  'SVN-Trebuchets',
  'SVN-Trench',
  'SVN-Tungsten Book',
  'SVN-Unthrift',
  'SVN-Valentina',
  'SVN-Vanessas Valentine',
  'SVN-Vanilla Daisy Pro',
  'SVN-Vantom',
  'SVN-Velvetberries',
  'SVN-Very Berry',
  'SVN-Victoria',
  'SVN-Wallington',
  'SVN-Wallows Ornaments',
  'SVN-Wallows',
  'SVN-Wednesday',
  'SVN-Whimsy',
  'SVN-Yahoo',
  'SVN-Zailla Script',
  'SVN-Zebra',
  'SVN-Zero',
  'SVN-Ziclets.ttf',
]

export const FontUVN = [
  'UVNAiCap_B',
  'UVNAiCap_R',
  'UVNAiCapNang',
  'UVNAiCapNhe',
  'UVNAnhHai_B',
  'UVNAnhHai_BI',
  'UVNAnhHai_I',
  'UVNAnhHai_R',
  'UVNAnhHaiNhe_I',
  'UVNAnhHaiNhe_R',
  'UVNAnhSang',
  'UVNBachDang_B',
  'UVNBachDang_BI',
  'UVNBachDang_I',
  'UVNBachDang_R',
  'UVNBachDangNang_I',
  'UVNBachDangNang_R',
  'UVNBachTuyet_B',
  'UVNBachTuyet_BI',
  'UVNBachTuyet_I',
  'UVNBachTuyet_R',
  'UVNBachTuyetNang_I',
  'UVNBachTuyetNang_R',
  'UVNBaiHoc_B',
  'UVNBaiHoc_BI',
  'UVNBaiHoc_I',
  'UVNBaiHoc_R',
  'UVNBaiSau_B',
  'UVNBaiSau_N',
  'UVNBaiSau_Nh',
  'UVNBaiSau_R',
  'UVNBaLe',
  'UVNBanhMi',
  'UVNBanTay',
  'UVNBayBuom_B',
  'UVNBayBuom_N',
  'UVNBayBuom_R',
  'UVNBayBuomHep_B',
  'UVNBayBuomHep_N',
  'UVNBayBuomHep_R',
  'UVNBenXuan_B',
  'UVNBenXuan_R',
  'UVNBinhDuong',
  'UVNBoQuen',
  'UVNBucThu',
  'UVNBuiDoi',
  'UVNButLong1',
  'UVNButLong2',
  'UVNCatBien_B',
  'UVNCatBien_BI',
  'UVNCatBien_I',
  'UVNCatBien_R',
  'UVNCatBienNhe_I',
  'UVNCatBienNhe_R',
  'UVNChimBien_B',
  'UVNChimBien_R',
  'UVNChimBienNang',
  'UVNChimBienNhe',
  'UVNChinhLuan_B',
  'UVNChinhLuan_BI',
  'UVNChinhLuan_I',
  'UVNChinhLuan_R',
  'UVNCHUKY',
  'UVNCoDien_B',
  'UVNCoDien_R',
  'UVNConThuy',
  'UVNDaLat_B',
  'UVNDaLat_R',
  'UVNDamCuoi_H',
  'UVNDamCuoi_R',
  'UVNDinhHon',
  'UVNDoiMoi',
  'UVNDungDan',
  'UVNDzungDakao',
  'UVNGiaDinh_B',
  'UVNGiaDinh_BI',
  'UVNGiaDinh_I',
  'UVNGiaDinh_R',
  'UVNGiaDinhHep_B',
  'UVNGiaDinhHep_BI',
  'UVNGiaDinhHep_I',
  'UVNGiaDinhHep_R',
  'UVNGiayTrang_B',
  'UVNGiayTrang_R',
  'UVNGioMay_B',
  'UVNGioMay_BI',
  'UVNGioMay_I',
  'UVNGioMay_R',
  'UVNGioMayNhe_I',
  'UVNGioMayNhe_R',
  'UVNGiongSong_B',
  'UVNGiongSong_BI',
  'UVNGiongSong_I',
  'UVNGiongSong_R',
  'UVNHaiBaTrung',
  'UVNHaiLong',
  'UVNHanViet',
  'UVNHoaDao',
  'UVNHOAKY',
  'UVNHoaTay1',
  'UVNHongHa_B',
  'UVNHongHa_BI',
  'UVNHongHa_I',
  'UVNHongHa_R',
  'UVNHongHaHep_B',
  'UVNHongHaHep_BI',
  'UVNHongHaHep_I',
  'UVNHongHaHep_R',
  'UVNHuongQue_B',
  'UVNHuongQue_N',
  'UVNHuongQue_R',
  'UVNKeChuyen1',
  'UVNKeChuyen2',
  'UVNKeChuyen3',
  'UVNKieu_B',
  'UVNKieu_R',
  'UVNKyThuat',
  'UVNLacLongQuan_B',
  'UVNLacLongQuan_R',
  'UVNLaXanh_B',
  'UVNLaXanh_BI',
  'UVNLaXanh_I',
  'UVNLaXanh_R',
  'UVNLYDO_',
  'UVNMangCau_B',
  'UVNMangCau_BI',
  'UVNMangCau_I',
  'UVNMangCau_R',
  'UVNMangCauNang_I',
  'UVNMangCauNang_R',
  'UVNMangTre',
  'UVNMauTim1',
  'UVNMauTim2',
  'UVNMayChuP_B',
  'UVNMayChuP_BI',
  'UVNMayChuP_I',
  'UVNMayChuP_R',
  'UVNMinhMap',
  'UVNMoiHong',
  'UVNMotMoi',
  'UVNMuaThu',
  'UVNMucCham',
  'UVNNguyenDu',
  'UVNNhan_B',
  'UVNNhan_BI',
  'UVNNhan_I',
  'UVNNhan_R',
  'UVNNhanNang_I',
  'UVNNhanNang_R',
  'UVNNhatKy_B',
  'UVNNhatKy_BI',
  'UVNNhatKy_I',
  'UVNNhatKy_R',
  'UVNPhuongTay',
  'UVNSachVo_B',
  'UVNSachVo_BI',
  'UVNSachVo_I',
  'UVNSachVo_R',
  'UVNSaigon_B',
  'UVNSaigon_BI',
  'UVNSaigon_I',
  'UVNSaigon_R',
  'UVNSangSong_B',
  'UVNSangSong_N',
  'UVNSangSong_R',
  'UVNThangGieng_I',
  'UVNThangGieng_R',
  'UVNThangVu',
  'UVNThanhBinh',
  'UVNThanhPho_B',
  'UVNThanhPho_R',
  'UVNThanhPhoNang',
  'UVNThayGiao_B',
  'UVNThayGiao_BI',
  'UVNThayGiao_I',
  'UVNThayGiao_R',
  'UVNThayGiaoNang_I',
  'UVNThayGiaoNang_R',
  'UVNThayGiaoNhe_I',
  'UVNThayGiaoNhe_R',
  'UVNThoiDai1',
  'UVNThoiDai2',
  'UVNThoiNay_B',
  'UVNThoiNay_BI',
  'UVNThoiNay_I',
  'UVNThoiNay_R',
  'UVNThoiNayNang_I',
  'UVNThoiNayNang_R',
  'UVNThuTu',
  'UVNTinTuc_B',
  'UVNTinTuc_BI',
  'UVNTinTuc_I',
  'UVNTinTuc_R',
  'UVNTinTucHep_B',
  'UVNTinTucHep_BI',
  'UVNTinTucHep_I',
  'UVNTinTucHep_R',
  'UVNTinTucHepThem_B',
  'UVNTinTucHepThem_R',
  'UVNTinTucNhe_I',
  'UVNTinTucNhe_R',
  'UVNVan_B',
  'UVNVan_R',
  'UVNVanChuong_B',
  'UVNVanChuong_I',
  'UVNVanChuong_R',
  'UVNVanChuongNang_R',
  'UVNVienDu',
  'UVNVietSach_B',
  'UVNVietSach_BI',
  'UVNVietSach_I',
  'UVNVietSach_R',
  'UVNViVi',
  'UVNVungTau',
]

export const FontUVF = [
  'UTM Facebook',
  'UTM FacebookB K&T',
  'UTM FacebookK&TBoldItalic',
  'UTM FacebookR K&T',
  'UVF A Charming Expanded',
  'UVF AbrilFatface Regular',
  'UVF Aire Roman Std',
  'UVF Angelic War',
  'UVF Aphrodite Pro',
  'UVF AQUUS-Regular',
  'UVF Assassin',
  'UVF Barmbrack',
  'UVF Bleeding Cowboys',
  'UVF Buttermilk Script',
  'UVF Centeria',
  'UVF Champion Script Pro',
  'UVF Chikita',
  'UVF Chops Normal',
  'UVF Cider Script',
  'UVF Daddys Girl',
  'UVF Didot LT Std',
  'UVF FiolexGirlVH',
  'UVF Geotica 2012',
  'UVF ginga',
  'UVF Haymaker',
  'UVF HeraBig-Black',
  'UVF Intro Inline',
  'UVF Jellyka Endless Voyage',
  'UVF Kaileen',
  'UVF Kingthings_Petrock_vi',
  'UVF Lavanderia Regular',
  'UVF Lobster12',
  'UVF Metroscript',
  'UVF Mishka',
  'UVF MussicaSwash',
  'UVF Narziss Swirls',
  'UVF Narziss-Drops',
  'UVF Parfait Scrip Stylistic',
  'UVF Pepita Script',
  'UVF Perla',
  'UVF Pistilli-Roman',
  'UVF Rapture Heavenly',
  'UVF Reina 12 Pro',
  'UVF RixLoveFool',
  'UVF SlimTony',
  'UVF Sofia Regular',
  'UVF SomeWeatz_with_swashes',
  'UVF Sreda',
  'UVF Turbayne',
  'UVF WazaLTPro',
  'UVF Youngblood',
  'VNF Shelia Regular',
]
