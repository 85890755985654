import { useCanvasContext } from '@components/Canvas/hooks'
import { useCallback } from 'react'
import { CanvasObjects } from '@components/Canvas'
import { propertiesToInclude } from '../constants/contants'
import { fabric } from 'fabric'
import { mIsLockObject, mLockObject } from '../utils/mLock'

function useCoreHandler() {
  const { canvas, activeObject, zoomRatio } = useCanvasContext()

  // Add objects to canvas
  const addObject = useCallback(
    (options,cb?: any) => {
      const { type, ...textOptions } = options
      const element = CanvasObjects[type].render(textOptions)
      //@ts-ignore
      const workarea = canvas.getObjects().find(obj => obj.id === 'workarea')
      canvas.add(element)
      element.center()

      // element.clipPath = workarea
      canvas.renderAll()
      if(cb){
        cb({element,canvas,workarea})
      }
    },
    [canvas]
  )

  const replaceImage = useCallback(
    (options,cb?: any) => {
      let _activeObj = canvas.getActiveObject()
      // console.log("replaceImage:",_activeObj)
      if(_activeObj && _activeObj.type=="image"){
        console.log("replaceImage:",options)
        //@ts-ignore
        // _activeObj.setSrc("https://reactjsexample.com/content/images/2022/05/Code-2022-01-19-27.jpg")
        // _activeObj.setSrc(options.image)
        _activeObj.setElement(options.image)
        _activeObj.center()
      }
      else{
        alert("Please chose image first")
      }
      canvas.renderAll()
      if(cb){
        cb({})
      }
    },
    [canvas]
  )

  const deleteActiveObject = useCallback(
    options => {
      console.warn('deleteActiveObject:', activeObject)
      if (activeObject) {
        canvas.remove(activeObject)
        // canvas.renderAll()
        canvas.discardActiveObject().renderAll()
      }
    },
    [canvas, activeObject]
  )

  // Update properties, optional set metadata if present
  const setProperty = useCallback(
    (property, value) => {
      if (activeObject) {
        activeObject.set(property, value)
        activeObject.setCoords()
        canvas.requestRenderAll()
      }
    },
    [activeObject, canvas]
  )

  const exportJSON = useCallback(() => {
    const json = canvas.toJSON(propertiesToInclude)
    return json
  }, [canvas])

  const exportJSON2Download = useCallback(()=>{
    var json = exportJSON()
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", "dataEditor.json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  },[canvas])

  const getFileNameDownload = useCallback(()=>{
    // @ts-ignore
    const filename = canvas.getObjects().find(object => object.id === 'filename' && object.type=="textbox")
    let _fileName = `photo`
    // @ts-ignore
    if(filename && filename.text){
      // @ts-ignore
      _fileName = `${filename.text}`
    }
    return _fileName
  },[canvas])

  const loadJSON = useCallback(
    json => {
      if (canvas) {
        canvas.loadFromJSON(json, () => {
          let _allObjects = canvas.getObjects()
          for(let o of _allObjects){
            console.log("obj:",o)
            if(mIsLockObject(o)){
              mLockObject(o, true)
            }
            //check font
            // @ts-ignore
            if(o.fontFamily!=null){
              const div = document.createElement("div");
              div.innerHTML = "hello"
              div.style.display = 'none'
              // @ts-ignore
              div.style.fontFamily = o.fontFamily
              document.body.appendChild(div); 
              // @ts-ignore              
              o.setCoords()
              setTimeout(()=>{
                // @ts-ignore      
                o.fontSize = o.fontSize+1
                o.setCoords()
                // @ts-ignore 
                console.log("render font 1")
                canvas.requestRenderAll()
                setTimeout(()=>{
                  // @ts-ignore      
                  o.fontSize = o.fontSize-1
                  o.setCoords()
                  // @ts-ignore 
                  console.log("render font 2")
                  canvas.requestRenderAll()
                },100)
              },100)
            }
          }
          
          // @ts-ignore
          const workarea = canvas.getObjects().find(object => object.id === 'workarea')
          // workarea.center()

          //zoom to fit
          if(canvas.height<workarea.height){
            canvas.zoomToPoint(new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2), canvas.height/(workarea.height+200))
          }
          
          canvas.requestRenderAll()

          
        })
      }
    },
    [canvas]
  )

  const setCanvasBackgroundColor = useCallback(
    color => {
      // @ts-ignore
      const workarea = canvas.getObjects().find(object => object.id === 'workarea')
      if (workarea) {
        workarea.set('fill', color)
        canvas.requestRenderAll()
      }
    },
    [canvas]
  )

  const resizeCanvas = useCallback(
    (width,height) => {
      // @ts-ignore
      const workarea = canvas.getObjects().find(object => object.id === 'workarea')
      if (workarea) {
        workarea.set('width', width)
        workarea.set('height', height)
        workarea.center()
        canvas.zoomToPoint(new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2), canvas.getHeight() / height)
        canvas.requestRenderAll()
      }
    },
    [canvas]
  )

  const lockObject = useCallback(
    (isLock = false) => {
      // @ts-ignore
      if (activeObject) {
        mLockObject(activeObject,isLock)
        activeObject.setCoords()
        canvas.requestRenderAll()
      }
    },
    [canvas,activeObject]
  )

  const disableSelectable = useCallback(
    (selectable = false) => {
      // @ts-ignore
      if (activeObject) {
        activeObject["selectable"] = selectable
        canvas.requestRenderAll()
      }
    },
    [canvas,activeObject]
  )

  const downloadPNG = useCallback(
    color => {
      // @ts-ignore
      const workarea = canvas.getObjects().find(object => object.id === 'workarea')
      if (workarea) {
        canvas.zoomToPoint(new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2), 1)
        const { left, top, width, height } = workarea
        console.warn('downloadPNG', workarea, left, top, width, height)
        // canvas.setZoom(1)
        const dataUrl = canvas.toDataURL({
          format: 'png',
          quality: 0.8,
          left,
          top,
          width,
          height,
          enableRetinaScaling: true,
        })
        if (dataUrl) {
          const anchorEl = document.createElement('a')
          anchorEl.href = dataUrl
          anchorEl.download = `${getFileNameDownload()}.png`
          document.body.appendChild(anchorEl)
          anchorEl.click()
          anchorEl.remove()
        }
        canvas.zoomToPoint(new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2), zoomRatio)
      }
    },
    [canvas]
  )

  return {
    exportJSON,
    exportJSON2Download,
    loadJSON,
    setCanvasBackgroundColor,
    resizeCanvas,
    addObject,
    replaceImage,
    setProperty,
    lockObject,
    disableSelectable,
    downloadPNG,
    deleteActiveObject,
  }
}

export default useCoreHandler
