import { useCoreHandler } from '@/components/Canvas/handlers'
import { useCanvasContext } from '@/components/Canvas/hooks'
import { useEffect, useState } from 'react'
import { CSSProperties } from 'react'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react'
import emptyColorPlaceholder from '@/assets/images/base-color-picker.png'
import { TwitterPicker } from 'react-color'
function ControlPickColor(props) {
  const [dropdown, setDropdown] = useState({
    displayColorPicker: false,
  })
  const [options, setOptions] = useState({
    color: '#ffffff',
  })
  const handleClick = () => {
    setDropdown({ ...dropdown, displayColorPicker: !dropdown.displayColorPicker })
  }
  const handleClose = () => {
    setDropdown({ ...dropdown, displayColorPicker: false })
  }

  useEffect(() => {
    if (props.color != options.color && props.color != null && props.color.length >= 6) {
      setOptions({ ...options, color: props.color })
    }
  }, [props.color])

  const popover: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
  }
  const cover: CSSProperties = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  return (
    <div>
      <div style={{ cursor: 'pointer' }} onClick={handleClick}>
        {options.color === '#ffffff' ? (
          <img style={{ height: '30px', display: 'flex' }} src={emptyColorPlaceholder} alt="color picker" />
        ) : (
          <div style={{ background: options.color }} className="editor-color-holder" />
        )}
      </div>
      {dropdown.displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <div>
            <TwitterPicker
              color={options.color}
              onChange={color => {
                setOptions({ color: color.hex })
                if (props && props.onChange) {
                  props.onChange(color)
                }
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default ControlPickColor
