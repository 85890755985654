import { useCoreHandler } from '@/components/Canvas/handlers'
import { useCanvasContext } from '@/components/Canvas/hooks'
import { FontSVN, FontUVF, FontUVN } from '@/services/fontList'
import {
  Modal,
  ButtonGroup,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import styled from '@emotion/styled'
const ListFont = {
  SVN: FontSVN,
  UVF: FontUVF,
  UVN: FontUVN,
}

const WrapGrid = styled.div`
  grid-auto-columns: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    font-size: 30px;
    text-align: center;
  }
`
const PageSize = 12
function ControlFonts(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { activeObject } = useCanvasContext()
  const { resizeCanvas } = useCoreHandler()
  const [typeFont, setTypeFont] = useState('')
  const [page, setPage] = useState(1)
  const [listFontName, setListFontName] = useState([])
  return (
    <div>
      <Button onClick={onOpen}>Fonts</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fonts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <ButtonGroup size="sm" isAttached variant="outline">
                {Object.keys(ListFont).map((v, i) => {
                  return (
                    <Button
                      key={i}
                      colorScheme={v == typeFont ? 'blue' : 'blue'}
                      variant={v == typeFont ? 'solid' : 'outline'}
                      onClick={() => {
                        setPage(1)
                        setTypeFont(v)
                        setListFontName(ListFont[v])
                      }}
                    >
                      {v}
                    </Button>
                  )
                })}
              </ButtonGroup>
            </div>
            <div>
              <WrapGrid style={{}}>
                {listFontName != null &&
                  listFontName.length > 0 &&
                  listFontName
                    .filter((v, i) => {
                      let _indexLast = page * PageSize
                      return i > _indexLast - PageSize && i < _indexLast - 1
                    })
                    .map((v, i) => {
                      return (
                        <div
                          key={`${typeFont}_${page}_${i}`}
                          style={{ fontFamily: v }}
                          onClick={() => {
                            props.onSelectFont && props.onSelectFont(v)

                            //set global
                            // @ts-ignore
                            window._fontSVG = v
                            // const sheet = new CSSStyleSheet()
                            // // @ts-ignore
                            // sheet.replaceSync(`svg text {font-family: ${v};}`)
                            // // @ts-ignore
                            // document.adoptedStyleSheets = [sheet]
                            onClose()
                          }}
                        >
                          {props.defaultText || v}
                        </div>
                      )
                    })}
              </WrapGrid>
              {listFontName != null && listFontName.length > 0 && (
                <div>
                  <Button
                    disabled={page <= 1}
                    onClick={() => {
                      setPage(page - 1)
                    }}
                  >
                    {' '}
                    truoc{' '}
                  </Button>
                  <Button
                    // @ts-ignore
                    disabled={page > parseInt(listFontName.length / PageSize)}
                    onClick={() => {
                      setPage(page + 1)
                    }}
                  >
                    {' '}
                    sau{' '}
                  </Button>
                </div>
              )}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ControlFonts
