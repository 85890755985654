export const tabItems = [
  {
    icon: 'templates',
    label: 'Şablonlar',
    name: 'templates',
  },
  {
    icon: 'images',
    label: 'Fotoğraf',
    name: 'images',
  },
  {
    icon: 'texts',
    label: 'Metin',
    name: 'text',
  },
  {
    icon: 'objects',
    label: 'Nesneler',
    name: 'objects',
  },
  {
    icon: 'objects',
    label: 'Layers',
    name: 'layers',
  },
  // {
  //   icon: 'musics',
  //   label: 'Müzik',
  //   name: 'musics',
  // },
  // {
  //   icon: 'videos',
  //   label: 'Videolar',
  //   name: 'videos',
  // },
]
