import { Input, InputGroup, InputLeftElement, Button, ButtonGroup, useForceUpdate } from '@chakra-ui/react'
import { SearchIcon, ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'
import { useCanvasContext } from '@components/Canvas/hooks'
import React from 'react'
import styled from '@emotion/styled'

const WrapItem = styled.div`
  & .a-layer-type {
    background: #e1e1e1;
    border-radius: 4px;
    padding: 0 4px;
  }
`
function LayerPanel() {
  const { canvas, activeObject } = useCanvasContext()
  const [count, setCount] = React.useState(1)
  const forceUpdate = useForceUpdate()
  console.log('render LayerPanel')

  return (
    <>
      {/* <div style={{ padding: '1rem 2rem' }}>Layers</div> */}
      <div style={{ padding: '1rem 2rem' }}>
        {canvas.getObjects().map((v: any, i) => {
          let _isSelectable = false
          let _isEvented = false
          let _isClipPath = false
          if (v) {
            if (v.selectable) {
              _isSelectable = true
            }
            if (v.evented) {
              _isEvented = true
            }
            if (v.clipPath != null) {
              _isClipPath = true
            }
          }
          let _canOrder = true
          let _canSet = true
          if (v.id == 'workarea') {
            _canOrder = false
            _canSet = false
          }
          return (
            <WrapItem key={i} style={{ background: 'white', marginBottom: 5, padding: 5 }}>
              <div>
                {i} -{' '}
                <span
                  className="a-layer-id"
                  onClick={() => {
                    var name = prompt('Please enter ID')
                    if (name) {
                      v.id = name
                      canvas.renderAll()
                      forceUpdate()
                    }
                  }}
                >
                  {v.id || 'No ID'}
                </span>{' '}
                - <span className="a-layer-type">{v.type || 'No Type'}</span>
              </div>
              <div>
                {_canOrder && (
                  <ButtonGroup size="sm" isAttached variant="outline">
                    <Button
                      size="sm"
                      onClick={() => {
                        canvas.sendBackwards(v)
                        canvas.renderAll()
                        setCount(count + 1)
                      }}
                    >
                      <ArrowUpIcon />
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => {
                        // v.bringToFront()//up top
                        v.bringForward()
                        canvas.renderAll()
                        setCount(count + 1)
                      }}
                    >
                      <ArrowDownIcon />
                    </Button>
                  </ButtonGroup>
                )}
                {_canSet && (
                  <ButtonGroup size="sm" isAttached variant="outline" colorScheme={'blue'}>
                    <Button
                      variant={_isSelectable == true ? 'solid' : 'outline'}
                      onClick={() => {
                        v.selectable = !_isSelectable
                        canvas.renderAll()
                        setCount(count + 1)
                      }}
                    >
                      Selectable
                    </Button>
                    <Button
                      variant={_isEvented == true ? 'solid' : 'outline'}
                      onClick={() => {
                        if (v.evented === false) {
                          delete v.evented
                        } else {
                          v.evented = false
                        }
                        canvas.renderAll()
                        setCount(count + 1)
                      }}
                    >
                      Evented
                    </Button>
                    <Button
                      variant={_isClipPath == true ? 'solid' : 'outline'}
                      onClick={() => {
                        if (v.clipPath != null) {
                          delete v.clipPath
                          canvas.renderAll()
                        } else {
                          //@ts-ignore
                          const workarea = canvas.getObjects().find(obj => obj.id === 'workarea')
                          activeObject.clipPath = workarea
                          canvas.renderAll()
                        }
                        canvas.renderAll()
                        setCount(count + 1)
                      }}
                    >
                      ClipPath
                    </Button>
                  </ButtonGroup>
                )}
              </div>
            </WrapItem>
          )
        })}
      </div>
    </>
  )
}
export default LayerPanel
