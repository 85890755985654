import { useState } from 'react'
import ClosePanel from './ClosePanel'
import PanelItemsList from './PanelsList/PanelsList'
import PanelItem from './PanelItem/PanelItem'
import './Panels.scss'
import useUploadFile from '@/components/Canvas/handlers/useUploadFile'

function Panels() {
  const [panelOpen, setPanelOpen] = useState(true)
  const [activeTab, setActiveTab] = useState('templates')
  const { fnParseFiles } = useUploadFile()
  const closePanel = () => {
    setPanelOpen(!panelOpen)
  }
  return (
    <div className="panels">
      <PanelItemsList
        setPanelOpen={setPanelOpen}
        panelOpen={panelOpen}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <PanelItem activeTab={activeTab} panelOpen={panelOpen} />
      <ClosePanel closePanel={closePanel} />
      <input
        type="file"
        id="custom_upload"
        style={{ display: 'none' }}
        onChange={e => {
          var files = e.target.files
          fnParseFiles(files)
          // var file = e.target.files[0]
          // console.log('file:', file)
          // var reader = new FileReader()
          // reader.onload = function (f) {
          //   var image = new Image()
          //   // @ts-ignore
          //   image.src = f.target.result
          //   image.onload = function () {
          //     const options = {
          //       type: 'image',
          //       image,
          //     }
          //     addObject(options)
          //   }
          // }
          // reader.readAsDataURL(file)
        }}
      />
    </div>
  )
}

export default Panels
