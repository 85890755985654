import { useCoreHandler } from '@/components/Canvas/handlers'
import { useCanvasContext } from '@/components/Canvas/hooks'
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
function ControlLock() {
  const { activeObject } = useCanvasContext()
  const { lockObject, disableSelectable } = useCoreHandler()
  return (
    <div>
      {activeObject != null && (
        <Button
          onClick={() => {
            lockObject(activeObject['mLock'] === true ? false : true)
          }}
        >
          {activeObject['mLock'] === true ? 'Un Lock' : 'Lock'}
        </Button>
      )}
      {/* {activeObject != null && (
        <Button
          onClick={() => {
            disableSelectable(activeObject['selectable'] === true ? false : true)
          }}
        >
          {activeObject['selectable'] === true ? 'Disable Select' : 'Selectable'}
        </Button>
      )} */}
    </div>
  )
}

export default ControlLock
