import './Navbar.scss'
import { Input, InputGroup, InputLeftElement, Button, ButtonGroup } from '@chakra-ui/react'
import { DownloadIcon, LogoIcon, GithubIcon } from './NavbarIcons'
import { useCoreHandler } from '@/components/Canvas/handlers'
function Navbar() {
  const { downloadPNG, exportJSON2Download, loadJSON } = useCoreHandler()
  return (
    <div className="navbar">
      <div className="navbar-left">
        <LogoIcon />
      </div>
      <div className="navbar-action-items">
        <ButtonGroup size="sm" isAttached variant="outline">
          {/* <DownloadIcon onClick={downloadPNG} /> */}
          <Button colorScheme="gray" onClick={downloadPNG}>
            Download
          </Button>
          <Button colorScheme="gray" onClick={exportJSON2Download}>
            Export json
          </Button>
          <Button
            colorScheme="gray"
            onClick={() => {
              document.getElementById('custom_upload_import').click()
            }}
          >
            Import json
            <input
              type="file"
              id="custom_upload_import"
              style={{ display: 'none' }}
              onChange={e => {
                var file = e.target.files[0]
                console.log('file:', file)
                var reader = new FileReader()
                reader.onload = function (f) {
                  var json: any = f.target.result
                  if (json && json.startsWith('{')) {
                    console.warn('json:', json)
                    loadJSON(JSON.parse(json))
                  }
                }
                reader.readAsText(file)
              }}
            />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default Navbar
