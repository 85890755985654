// @ts-nocheck
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useCanvasContext } from '@/components/Canvas/hooks'
import { useEffect, useState } from 'react'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Button } from '@chakra-ui/react'
import './ImageToolbox.scss'
import { useCoreHandler } from '@/components/Canvas/handlers'
import ControlFill from '../Controls/Fill'
import ControlLayer from '../Controls/Layer'
import { FontList } from '@/services/fontList'
import ControlFonts from '../Controls/Fonts'
import useUploadFile from '@/components/Canvas/handlers/useUploadFile'

function ImageTool() {
  const { activeObject } = useCanvasContext()
  const { setProperty, replaceImage } = useCoreHandler()
  const { fnUpload } = useUploadFile()
  return (
    <div className="editor-toolbox-container">
      <div className="editor-toolbox text">
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              fnUpload(
                ({ image }) => {
                  const options = {
                    type: 'image',
                    image,
                  }
                  replaceImage(options)
                },
                { reader: 'dataurl' }
              )
            }}
          >
            Replace Image
          </Button>
        </div>
        <div className="section-two"></div>
      </div>
    </div>
  )
}

export default ImageTool
