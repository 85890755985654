import { useCoreHandler } from '@/components/Canvas/handlers'
import { useCanvasContext } from '@/components/Canvas/hooks'
import { useEffect, useState } from 'react'
import { CSSProperties } from 'react'
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  RadioGroup,
  Stack,
  Radio,
  Select,
  useForceUpdate,
} from '@chakra-ui/react'
import emptyColorPlaceholder from '@/assets/images/base-color-picker.png'
import { TwitterPicker } from 'react-color'
import ControlPickColor from './PickColor'
function ControlFill() {
  const { activeObject } = useCanvasContext()
  const { setProperty } = useCoreHandler()
  const forceUpdate = useForceUpdate()
  return (
    <div>
      {activeObject != null && (
        <div style={{ position: 'relative' }}>
          <Popover>
            <PopoverTrigger>
              <Button>Fill</Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Confirmation!</PopoverHeader>
              <PopoverBody>
                <div>
                  <div>
                    <div>Fill Color</div>
                    <div>
                      <ControlPickColor
                        color={activeObject['fill']}
                        onChange={color => {
                          if (color && color.hex) {
                            setProperty('fill', color.hex)
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div>Stroke Width</div>
                    <div>
                      <Select
                        placeholder="Select stroke width"
                        value={activeObject['strokeWidth']}
                        onChange={v => {
                          console.log('onChange:', v, v.currentTarget.value)
                          setProperty('strokeWidth', Number(v.currentTarget.value))
                          forceUpdate()
                        }}
                      >
                        {new Array(30).fill(1).map((v, i) => {
                          return (
                            <option key={i} value={String(i + 1)}>
                              {String(i + 1)}
                            </option>
                          )
                        })}
                      </Select>
                    </div>
                  </div>
                  <div>
                    <div>Stroke Color</div>
                    <div>
                      <ControlPickColor
                        color={activeObject['stroke']}
                        onChange={color => {
                          if (color && color.hex) {
                            setProperty('stroke', color.hex)
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div>Stroke Width</div>
                    <div>
                      <RadioGroup
                        onChange={v => {
                          console.log('onChange:', v)
                          setProperty('strokeLineJoin', v)
                          forceUpdate()
                        }}
                        value={activeObject['strokeLineJoin']}
                      >
                        <Stack direction="row">
                          <Radio value="bevel">bevel</Radio>
                          <Radio value="round">round</Radio>
                          <Radio value="miter">miter</Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </div>
                  <div>
                    <div>Paint First</div>
                    <div>
                      <RadioGroup
                        onChange={v => {
                          console.log('onChange:', v)
                          setProperty('paintFirst', v)
                          forceUpdate()
                        }}
                        value={activeObject['paintFirst']}
                      >
                        <Stack direction="row">
                          <Radio value="fill">fill</Radio>
                          <Radio value="stroke">stroke</Radio>
                        </Stack>
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default ControlFill
