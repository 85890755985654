import { fabric } from 'fabric'

const defaultConfig = {
  borderOpacityWhenMoving: 0.2,
}
export const CanvasObjects = {
  text: {
    render: options => {
      const { text, ...textOptions } = options
      return new fabric.Textbox(text, {...defaultConfig,...textOptions})
    },
  },
  image: {
    render: options => {
      if (options.image) {
        return new fabric.Image(options.image,{...defaultConfig})
      }
      return new fabric.Image()
    },
  },
}
