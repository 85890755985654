import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import React from 'react'
import { useCoreHandler } from '@/components/Canvas/handlers'
function TemplatesPanel() {
  const { downloadPNG, exportJSON2Download, loadJSON } = useCoreHandler()
  const [templates, setTemplates] = React.useState(null)
  const [errorTemplates, setErrorTemplates] = React.useState(null)
  React.useEffect(() => {
    fetch('/templates/templates.json')
      .then(response => {
        if (!response.ok) {
          console.error('Cannot load template:', response.status)
          setErrorTemplates({ msg: 'Load template error!' })
        }
        return response.json()
      })
      .then(json => {
        console.log(json)
        setTemplates(json)
      })
      .catch(function () {
        setErrorTemplates({ msg: 'Load template error!' })
      })
  }, [])
  return (
    <>
      <div style={{ padding: '1rem 2rem' }}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input style={{ background: '#fff' }} type="tel" placeholder="Search templates" />
        </InputGroup>
        <div style={{ marginTop: 10 }}>
          {templates != null &&
            templates.list &&
            templates.list
              .filter((v, i) => {
                return v.hide !== true
              })
              .map((v, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      let _url = v.file
                      fetch(_url)
                        .then(response => {
                          if (!response.ok) {
                            console.error('Cannot load template:', response.status)
                          }
                          return response.json()
                        })
                        .then(json => {
                          console.log(json)
                          loadJSON(json)
                        })
                        .catch(function () {
                          console.error('Cannot load template')
                        })
                    }}
                    style={{ background: 'white', padding: 10, marginBottom: 5, cursor: 'pointer' }}
                  >
                    {v.name}
                  </div>
                )
              })}
        </div>
      </div>
    </>
  )
}
export default TemplatesPanel
