import { fabric } from 'fabric'

export function mLockObject(obj: any,isLock: any){
    obj.lockMovementX = isLock
    obj.lockMovementY = isLock
    obj.lockScalingX = isLock
    obj.lockScalingY = isLock
    obj.lockRotation = isLock
    obj["mLock"] = isLock
}

export function mIsLockObject(obj: any){
    return obj["mLock"] === true
}