import { useCanvasContext } from '@/components/Canvas/hooks'
import DefaultToolbox from './DefaultToolbox/DefaultToolbox'
import TextToolbox from './TextToolbox/TextToolbox'
import ImageToolbox from './ImageToolbox/ImageToolbox'
import './Toolbox.scss'

function Toolbox() {
  const { activeObject } = useCanvasContext()
  if (!activeObject) {
    return <DefaultToolbox />
  }
  const activeObjectType = activeObject.type

  return (
    <div>
      {activeObjectType === 'textbox' ? (
        <TextToolbox />
      ) : activeObjectType === 'image' ? (
        <ImageToolbox />
      ) : (
        <DefaultToolbox />
      )}
    </div>
  )
}

export default Toolbox
