import './FooterMenu.scss'
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, Button, Icon } from '@chakra-ui/react'
import { PhoneIcon, AddIcon, Search2Icon } from '@chakra-ui/icons'
import { useCanvasContext } from '@components/Canvas/hooks'
import { fabric } from 'fabric'
function FooterMenu() {
  const { canvas, setActiveObject, activeObject, setZoomRatio } = useCanvasContext()
  return (
    <div className="footermenu">
      <Button
        onClick={() => {
          let zoomRatio = canvas.getZoom()

          if (zoomRatio < 4) {
            // zoomRatio += 0.04
            zoomRatio = Math.round(zoomRatio * 100 + 4) / 100
          }
          console.log('zoomin', zoomRatio)
          setZoomRatio(zoomRatio)
        }}
      >
        <Icon as={Search2Icon} /> In
      </Button>
      <Button
        onClick={() => {
          let zoomRatio = canvas.getZoom()
          if (zoomRatio > 0.1) {
            // zoomRatio -= 0.04
            zoomRatio = Math.round(zoomRatio * 100 - 4) / 100
          }
          console.log('zoomout', zoomRatio)
          setZoomRatio(zoomRatio)
        }}
      >
        <Icon as={Search2Icon} /> Out
      </Button>
      <Button
        onClick={() => {
          // @ts-ignore
          const workarea = canvas.getObjects().find(object => object.id === 'workarea')
          // workarea.center()

          //zoom to fit
          if (canvas.height < workarea.height) {
            var ratio = canvas.height / (workarea.height + 200)
            console.log('ratio', ratio)
            canvas.zoomToPoint(new fabric.Point(canvas.getWidth() / 2, canvas.getHeight() / 2), ratio)
          }
          canvas.requestRenderAll()
        }}
      >
        <Icon as={Search2Icon} /> Fit
      </Button>
    </div>
  )
}

export default FooterMenu
