import { useCanvasContext } from '@components/Canvas/hooks'
import { createRef, useCallback, useEffect, useRef } from 'react'

const configUploadFile = {
    fnParseFiles: (opts: any)=>{},
    config: null,
    fnParseConfigAndParseFiles: (files: any)=>{
        if(configUploadFile.config && configUploadFile.config.reader){
            if(configUploadFile.config.reader=="dataurl"){
                var file = files[0]
                console.log('file:', file)
                var reader = new FileReader()
                reader.onload = function (f) {
                    var image = new Image()
                    // @ts-ignore
                    image.src = f.target.result
                    image.onload = function () {
                        if(configUploadFile.fnParseFiles){
                            configUploadFile.fnParseFiles({image})
                        }
                    }
                }
                reader.readAsDataURL(file)
            }
            else if(configUploadFile.config.reader=="text"){
                var file = files[0]
                console.log('file:', file)
                var reader = new FileReader()
                reader.onload = function (f) {
                    // @ts-ignore
                    var text = f.target.result
                    if(configUploadFile.fnParseFiles){
                        configUploadFile.fnParseFiles({text})
                    }
                }
                reader.readAsText(file)
            }
        }
        else{
            if(configUploadFile.fnParseFiles){
                configUploadFile.fnParseFiles({files})
            }
        }
    }
}
function useUploadFile() {
  const fnUpload = (fn: any,config: any)=>{
    configUploadFile.fnParseFiles = fn
    configUploadFile.config = config
    console.warn("fnUpload:",fn,configUploadFile.fnParseFiles)
    document.getElementById('custom_upload').click()
  }
  return {
    fnUpload,
    fnParseFiles: (files)=>{
        console.warn("fnParseFiles:",files,configUploadFile.fnParseFiles)
        if(configUploadFile.fnParseConfigAndParseFiles){
            configUploadFile.fnParseConfigAndParseFiles(files)
        }
    }
  }
}

export default useUploadFile
