import { useCoreHandler } from '@/components/Canvas/handlers'
import { useCanvasContext } from '@/components/Canvas/hooks'
import {
  Modal,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
function ControlResize() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { activeObject } = useCanvasContext()
  const { resizeCanvas } = useCoreHandler()
  return (
    <div>
      <Button onClick={onOpen}>Resize</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Resize</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <Button
                onClick={() => {
                  resizeCanvas(1200, 1200)
                  onClose()
                }}
              >
                Facebook Post 1200x1200
              </Button>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            {/* <Button variant="ghost">Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ControlResize
