import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import { useCoreHandler } from '@/components/Canvas/handlers'
import useUploadFile from '@/components/Canvas/handlers/useUploadFile'
import { useCanvasContext } from '@components/Canvas/hooks'
function ImagesPanel() {
  const { addObject, replaceImage } = useCoreHandler()
  const { canvas, activeObject } = useCanvasContext()
  const { fnUpload } = useUploadFile()
  return (
    <>
      <div className="panel-text" style={{ padding: '1rem 2rem' }}>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
          <Input style={{ background: '#fff' }} type="tel" placeholder="Search images" />
        </InputGroup>
        <div className="label">Click to add image</div>
        <div className="add-text-items">
          <div
            onClick={() => {
              fnUpload(
                ({ image }) => {
                  const options = {
                    type: 'image',
                    image,
                  }
                  addObject(options)
                },
                { reader: 'dataurl' }
              )
            }}
            className="add-text-item add-heading"
          >
            Upload Image
          </div>
          {/* <div
            onClick={() => {
              if (activeObject) {
                fnUpload(
                  ({ image }) => {
                    const options = {
                      type: 'image',
                      image,
                    }
                    replaceImage(options)
                  },
                  { reader: 'dataurl' }
                )
              } else {
                alert('Please chose image first')
              }
            }}
            className="add-text-item add-heading"
          >
            Replace Image
          </div> */}
          <div
            onClick={() => {
              fnUpload(
                ({ image }) => {
                  const options = {
                    type: 'image',
                    image,
                  }
                  addObject(options, ({ element, canvas, workarea }) => {
                    element.set('left', workarea.left)
                    element.set('top', workarea.top)
                    // element.set('width', workarea.width)
                    // element.set('height', workarea.height)
                    element.set('scaleX', workarea.width / element.width)
                    element.set('scaleY', workarea.height / element.height)
                    element.setCoords()
                    canvas.requestRenderAll()
                  })
                },
                { reader: 'dataurl' }
              )
            }}
            className="add-text-item add-heading"
          >
            Upload Frame
          </div>
        </div>
      </div>
    </>
  )
}
export default ImagesPanel
